import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Head from "../shared/head"
import Particle from "../shared/particle"

const config = require('../../gatsby-config');

// import '../css/blog-post.css';

export default function Template({ data }) {
  const { markdownRemark: post } = data
  const { edges } = data.allWebMentionEntry

  return (
    <div>
      <Particle />
      <div className="flex flex-wrap ">
          <div className="w-full md:w-3/12"></div>
          <div className="w-full md:w-6/12 relative">
              <Head />

              <Helmet title={`pikseladam - ${post.frontmatter.title}`} />
              <article className="h-entry hidden">
                <div className="p-name">{post.frontmatter.title}</div>
                <a className="u-url" href="https://pikseladam.com">Pikseladam</a>
                <p>Published by <a className="p-author h-card" href="https://pikseladam.com">Pikseladam</a>
                  on <time 
                      className=" dt-published"
                      itemProp="datepublished" 
                      dateTime={post.frontmatter.date}
                    >
                      {
                        new Date(post.frontmatter.date)
                          .toISOString()
                          .replace('Z', '') + "+03:00"
                      }
                    </time>
                    <a className="p-name u-url" rel="me" href="https://pikseladam.com/">Pikseladam</a>
                    <img className="u-photo" src="https://pikseladam.com/avatar.jpg" alt="pikseladam logo"/>
                    <span className="p-note">I'm Lead PLM Systems & Software Engineer. I code stuff & design things. #Arts for art. Working on http://turrsu.com and https://pikseladam.com for fun. #webmentions on pikseladam.</span>
                </p>
              
                <p className="p-summary">{post.html}</p>
              
                <div className="e-content">
                  <p>{post.html}</p>
                </div>
              </article>

              <div className="font-bold text-4xl text-gray-800 clearfix mt-6 px-4 text-center">
                <Link to="/" ><img className="z-40 m-auto w-40" src="https://pikseladam.com/pikseladam.png" alt="pikseladam logo"/></Link>
              </div>
              <div className="px-4 mt-6">
                <div className="text-2xl font-bold mb-6">{post.frontmatter.title}</div>
                <div
                  className="blog-post-content"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
                <div className="text-sm text-gray-500 mt-4">{post.frontmatter.date}</div>
                <div className="flex mt-16">
                  <div className="py-4">
                    <div className="text-base">Yorumlar / Comments</div>
                    {edges.map(({ node: post }) => {
                      return (
                        <div className="text-base flex mt-4">
                          <div className="w-1/12 mr-4">
                            <img src={post.author.photo} alt="yorum avatar"/>
                          </div>
                          <div className="w-11/12">
                            <div><span className="font-bold">{post.author.name}</span> - <span className="text-gray-500 text-sm">{post.published}</span></div>
                            <div>{post.content.text}</div>
                          </div>
                        </div>
                      )
                    })}
                    <div className="mt-4 mb-10">
                    <form method="post" action="https://webmention.io/pikseladam.com/webmention">
                      <p className="text-gray-600 text-xs italic mb-2">Bu site webmention destekler.  / This website supports webmentions.</p>
                      <input name="target" value={`${config.siteMetadata.siteURL}${post.frontmatter.path}`}type="hidden" />
                      <input className="w-full sm:w-1/2 appearance-none rounded-l h-8  bg-gray-200 text-blue-700  px-4 leading-tight focus:outline focus:bg-blue-100 text-base focus:border-gray-500" name="source" type="url" placeholder="your webmention url" />
                      <input className="w-full sm:w-1/2 bg-blue-300 hover:bg-gray-400 h-8 text-gray-800 text-base cursor-pointer  px-4 rounded-r" value="Send Webmention" type="submit" />
                    </form>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className="w-full md:w-3/12"></div>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($permalink: String,$path: String) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
    allWebMentionEntry(filter: { wmTarget: { eq: $permalink } }) {
      edges {
        node {
          wmTarget
          wmSource
          wmProperty
          wmId
          type
          url
          likeOf
          author {
            url
            type
            photo
            name
          }
          content {
            text
          }
          published(formatString: "DD/MM/YYYY")
        }
      }
    }
  }
`

