/**
 * Configure your Gatsby site with this file.
 *
 * See: https://www.gatsbyjs.org/docs/gatsby-config/
 */

module.exports = {
  /* Your site config here */
  siteMetadata: {
    title: `pikseladam indie blog`,
    author: `pikseladam`,
    description: `Pikseladam bir indie blog sitesidir. Pikseladam sitesini; makalelerimi, notlarımı, ve internette bulduğum güzel şeyleri paylaşmak için kullanıyorum.`,
    siteURL: `https://pikseladam.com`,
  },
  plugins: [
      "gatsby-plugin-catch-links", 
      "gatsby-plugin-react-helmet",
      {
        resolve: "gatsby-source-filesystem",
        options: {
          path: `${__dirname}/src/pages`,
          name: "pages",
        },
      },
      {
        resolve: `gatsby-plugin-google-analytics`,
        options: {
          trackingId: "UA-145459351-2",
        },
      },
      {
        resolve: "gatsby-plugin-postcss",
      },
      {
        resolve: "gatsby-transformer-remark",
        options: {
          plugins: [], // just in case those previously mentioned remark plugins sound cool :)
        },
      },
      `gatsby-plugin-sharp`,
      {
        resolve: `gatsby-transformer-remark`,
        options: {
          plugins: [
            {
              resolve: `gatsby-remark-images`,
              options: {
                // It's important to specify the maxWidth (in pixels) of
                // the content container as this plugin uses this as the
                // base for generating different widths of each image.
                maxWidth: 620,
              },
            },
          ],
        },
      },
      {
        resolve: "gatsby-plugin-google-fonts",
        options: {
          fonts: [
            `Sen:400,700` // you can also specify font weights and styles
          ],
          display: `swap`
        }
      },
      {
        resolve: `gatsby-plugin-webmention`,
        options: {
          username: "pikseladam.com",
          identity: {
            github: "tcgumus",
            twitter: "tc_gumus",
            email: "tunacaglargumus@gmail.com"
          },
          mentions: true,
          pingbacks: true,
          domain: "pikseladam.com",
          fetchLimit: 10000, // number of webmentions to fetch
          token: process.env.WEBMENTIONS_TOKEN
        }
      },
      {
        resolve: `gatsby-plugin-feed`,
        options: {
          query: `
            {
              site {
                siteMetadata {
                  title
                  description
                  siteURL
                  site_url: siteURL
                }
              }
            }
          `,
          feeds: [
            {
              serialize: ({ query: { site, allMarkdownRemark } }) => {
                return allMarkdownRemark.edges.map(edge => {
                  return Object.assign({}, edge.node.frontmatter, {
                    description: edge.node.excerpt,
                    date: edge.node.frontmatter.date,
                    url: site.siteMetadata.siteURL + edge.node.frontmatter.path,
                    guid: site.siteMetadata.siteURL + edge.node.frontmatter.path,
                    custom_elements: [{ "content:encoded": edge.node.html }],
                  })
                })
              },
              query: `
                {
                  allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] },
                  ) {
                    edges {
                      node {
                        excerpt
                        html
                        frontmatter {
                          title
                          date
                          path
                        }
                      }
                    }
                  }
                }
              `,
              output: "/rss.xml",
              title: "Pikseladam.com RSS Feed",
            },
          ],
        },
      }
    
  ]
}
